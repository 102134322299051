import { Box, Flex, Image, Text } from '@chakra-ui/react';
import Link from 'next/link';

export default function Home() {
  return (
    <Flex
      justifyContent="center"
      height="100vh"
      alignItems="center"
      flexDirection="column"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent={['center', 'flex-start']}
        gap="20px"
      >
        <Image
          src={'/red-icon.png'}
          alt="Inspired"
          width={['29px', '41px']}
          height={['24px', '37px']}
        />
        <Image src="/images/logo-wordmark-white.svg" alt="Inspired" w="98px" />
      </Flex>
      <Flex position="absolute" bottom="60px" maxW="300px" flexDir="column">
        <Box>
          <Text fontSize="xs" color="#fff">
            Visit{' '}
            <Link href="https://inspired.com">
              <a target="_blank" style={{ textDecoration: 'underline' }}>
                Inspired.com
              </a>
            </Link>{' '}
            to learn more.
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}

// export function getServerSideProps({}: /* req */ GetServerSidePropsContext) {
//   const redirect = '/donor-portal/login';
//   return {
//     redirect: {
//       destination: redirect,
//       permanent: false
//     }
//   };
// }
